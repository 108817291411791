#page-add .blocks-form,
#page-edit .blocks-form {
  .block-editor-separator,
  .block-editor-__button,
  .block-editor-heading {
    &.has--backgroundColor--grey {
      background-color: var(--color-surface-container-high);
      color: var(--color-inverse-on-surface);
    }
    &.has--backgroundColor--mainColor {
      background-color: var(--color-primary-container);
      color: var(--color-inverse-on-surface);
    }
  }
  .block-editor-gridBlock {
    &.has--backgroundColor--grey {
      background-color: var(--color-surface-container-high);
      .block.teaser {
        .teaser-item {
          background-color: var(--color-surface-container);
        }
      }
    }
    &.has--backgroundColor--mainColor {
      background-color: var(--color-primary-container);
      .block.teaser {
        .teaser-item {
          background-color: var(--color-primary-container);
        }
      }
    }
  }
}

.blocks-group-wrapper {
  @include color-block-change-vertical-spacing();
  &.grey,
  &.has--backgroundColor--grey {
    background-color: var(--color-surface-container-high);
    color: var(--color-inverse-on-surface);
  }
  &.mainColor,
  &.has--backgroundColor--mainColor {
    background-color: var(--color-primary-container);
    color: var(--color-on-primary-container);
  }
}

.block.has--backgroundColor--grey {
  background: var(--color-surface-container-high) !important;
  color: var(--color-inverse-on-surface) !important;
  &.teaser {
    .teaser-item {
      color: var(--color-inverse-on-surface) !important;
    }
  }
  &.gridBlock,
  &.gridBlock h2.headline,
  &.gridBlock .slate,
  &.gridBlock .slate:not(.inner) {
    background: var(--color-surface-container-high) !important;
    color: var(--color-inverse-on-surface) !important;
  }
}
#page-document .block.has--backgroundColor--mainColor {
  background-color: var(--color-primary-container);
  color: var(--color-on-primary-container);
  &.teaser {
    .teaser-item {
      color: var(--color-on-primary-container);
    }
  }
  &.gridBlock,
  &.gridBlock h2.headline,
  &.gridBlock .slate,
  &.gridBlock .slate:not(.inner) {
    background-color: var(--color-primary-container);
    color: var(--color-on-primary-container);
  }
  &.gridCTA .content .cta .ctaButton {
    @include cta-color-gradient-reverse();
  }
}
.block.follow_us {
  &.has--backgroundColor--grey,
  &.has--backgroundColor--mainColor {
    .social-networks {
      .item {
        a.social-network {
          &:hover {
            svg.social-network.icon {
              color: var(--color-primary) !important;
            }
          }
          svg.social-network.icon {
            color: var(--color-inverse-on-surface) !important;
          }
        }
      }
    }
  }
}
