// Spacing
$spacing-xsmall: 10px !default;
$spacing-small: 20px !default;
$spacing-medium: 40px !default;
$spacing-large: 60px !default;
$spacing-xlarge: 80px !default;

// Colors
$lightgrey: var(--color-surface-container);

// Maps
$font-weights: (
  thin: var(--font-weight-thin),
  extra-light: var(--font-weight-extra-light),
  light: var(--font-weight-light),
  regular: var(--font-weight-regular),
  medium: var(--font-weight-medium),
  semi-bold: var(--font-weight-semi-bold),
  bold: var(--font-weight-bold),
  extra-bold: var(--font-weight-extra-bold),
  bolder: var(--font-weight-bolder),
) !default;

$font-sizes: (
  2xs: var(--font-size-2xs),
  xs: var(--font-size-xs),
  s: var(--font-size-s),
  m: var(--font-size-m),
  l: var(--font-size-l),
  xl: var(--font-size-xl),
  2xl: var(--font-size-2xl),
  3xl: var(--font-size-3xl),
  4xl: var(--font-size-4xl),
  5xl: var(--font-size-5xl),
) !default;

$line-heights: (
  xs: var(--line-height-xs),
  s: var(--line-height-s),
  m: var(--line-height-m),
  l: var(--line-height-l),
  xl: var(--line-height-xl),
  2xl: var(--line-height-2xl),
  3xl: var(--line-height-3xl),
  4xl: var(--line-height-4xl),
  5xl: var(--line-height-5xl),
) !default;

$custom-fontname: 'Anonymous Pro' !default;

// Change of color
$color-block-change-vertical-spacing: $spacing-large !default;

@mixin graffiti() {
  font-family: 'Sprite-Graffiti';
  letter-spacing: 0.2rem;
}

@mixin graffiti_shadown() {
  font-family: 'Sprite-Graffiti-Shadow';
  letter-spacing: 0.2rem;
}

// CTA
@mixin cta-animation() {
  transition: all 1500ms ease;
}

@mixin cta-color-gradient() {
  @include color-gradient(var(--color-primary-container), var(--color-primary));
}

@mixin cta-color-gradient-reverse() {
  @include color-gradient(
    var(--color-surface-container-highest),
    var(--color-surface-container-high)
  );
}

@mixin color-gradient($color1, $color2) {
  position: relative;
  z-index: 1;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  border-color: $color1;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(90deg, $color1 0%, $color2 100%);
  moz-transition: all 0.4s ease-in-out;
  outline: none !important;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  vertical-align: middle;
  &::after {
    position: absolute;
    z-index: -1;
    bottom: 100%;
    left: 50%;
    width: 150%;
    height: 150%;
    background: linear-gradient(90deg, $color2 0%, $color1 100%);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    content: '';
    transform: translateX(-50%);
    transition-duration: 700ms;
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
  &:hover::after {
    bottom: -50%;
  }
}

@mixin cta-button() {
  padding: $spacing-small $spacing-medium;
  border-radius: $spacing-small;
  gap: $spacing-xsmall;
  @include add(size, l);
  @include add(weight, bold);
}

@mixin cta-button-grid() {
  width: 100%;
  justify-content: center;
  padding: $spacing-small $spacing-small;
  border-radius: $spacing-small;
  gap: $spacing-xsmall;
  @include add(size, m);
  @include add(weight, normal);
}

// Animations

@mixin animate($distance, $delay) {
  -webkit-animation-duration: $delay;
  animation-duration: $delay;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
  @-webkit-keyframes fadeInRightBig {
    0% {
      opacity: 0;
      -webkit-transform: translate3d($distance, 0, 0);
      transform: translate3d($distance, 0, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInRightBig {
    0% {
      opacity: 0;
      -webkit-transform: translate3d($distance, 0, 0);
      transform: translate3d($distance, 0, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
}
