:root {
  --eventbrite-cta-background-color: var(--color-primary-container);
  --eventbrite-cta-border: var(--color-primary-container);
  --eventbrite-cta-text-color: var(--color-on-primary-container);
  --eventbrite-cta-padding: 20px 40px;
  --eventbrite-cta-font-size: 24px;
  --eventbrite-cta-font-weight: 500;
  --eventbrite-cta-hover-background-color: var(--color-primary);
  --eventbrite-cta-hover-border: var(--color-primary);
  --eventbrite-cta-hover-font-size: 24px;
  --eventbrite-cta-hover-font-weight: 500;
  --eventbrite-placeholder-header-font-size: 20px;
  --eventbrite-placeholder-header-font-weight: 700;
  --eventbrite-placeholder-text-font-size: var(--eventbrite-cta-font-size);
  --eventbrite-placeholder-text-font-weight: var(--eventbrite-cta-font-weight);
}

.block.checkoutBlock {
  .wrapper {
    padding-top: $spacing-small;
    .eventbrite-cta {
      border-radius: 20px;
    }
  }
}
