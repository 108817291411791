body {
  color: var(--color-on-background);
}

a {
  color: var(--color-surface-container-high);
  &:hover {
    color: var(--color-surface-container-high);
  }
}

h1.documentFirstHeading {
  @include graffiti();
  margin-bottom: $spacing-medium;
}

.block h2.headline {
  @include block-title();
  margin-top: $spacing-xlarge;
  margin-bottom: $spacing-large;
}

.documentDescription {
  padding: $spacing-small $spacing-medium;
  border-top: var(--color-surface-container-high) 1px solid;
  border-bottom: var(--color-surface-container-high) 1px solid;
  color: var(--color-on-background);
  @include add(size, l);
  @include add(weight, bold);
  @include add(height, xl);
}

button {
  font-family: var(--custom-main-font);
}

@mixin h2-headline() {
  @include graffiti();
  @include add(size, 5xl);
  @include add(weight, bold);
  @include add(height, 5xl);
  @media only screen and (max-width: $tablet-breakpoint) {
    text-align: center;
  }
}
