:root {
  // Color Palette
  --color-primary: rgb(226 0 26);
  --color-surface-tint: rgb(63 95 144);
  --color-on-primary: rgb(255 255 255);
  --color-primary-container: rgb(173, 0, 21);
  --color-on-primary-container: rgb(255 255 255);
  --color-secondary: rgb(0 71 106);
  --color-on-secondary: rgb(255 255 255);
  --color-secondary-container: rgb(65 122 161);
  --color-on-secondary-container: rgb(255 255 255);
  --color-tertiary: rgb(0 71 104);
  --color-on-tertiary: rgb(255 255 255);
  --color-tertiary-container: rgb(63 122 160);
  --color-on-tertiary-container: rgb(255 255 255);
  --color-error: rgb(140 0 9);
  --color-on-error: rgb(255 255 255);
  --color-error-container: rgb(218 52 46);
  --color-on-error-container: rgb(255 255 255);
  --color-background: rgb(195, 200, 202);
  --color-on-background: rgb(39 38 38);
  --color-surface: rgb(249 249 255);
  --color-on-surface: rgb(25 28 32);
  --color-surface-variant: rgb(224 226 236);
  --color-on-surface-variant: rgb(63 67 74);
  --color-outline: rgb(92 95 103);
  --color-outline-variant: rgb(120 123 131);
  --color-shadow: rgb(0 0 0);
  --color-scrim: rgb(0 0 0);
  --color-inverse-surface: rgb(46 48 53);
  --color-inverse-on-surface: rgb(240 240 247);
  --color-inverse-primary: rgb(168 200 255);
  --color-primary-fixed: rgb(86 117 168);
  --color-on-primary-fixed: rgb(255 255 255);
  --color-primary-fixed-dim: rgb(60 93 142);
  --color-on-primary-fixed-variant: rgb(255 255 255);
  --color-secondary-fixed: rgb(65 122 161);
  --color-on-secondary-fixed: rgb(255 255 255);
  --color-secondary-fixed-dim: rgb(36 97 135);
  --color-on-secondary-fixed-variant: rgb(255 255 255);
  --color-tertiary-fixed: rgb(63 122 160);
  --color-on-tertiary-fixed: rgb(255 255 255);
  --color-tertiary-fixed-dim: rgb(33 97 134);
  --color-on-tertiary-fixed-variant: rgb(255 255 255);
  --color-surface-dim: rgb(217 217 224);
  --color-surface-bright: rgb(249 249 255);
  --color-surface-container-lowest: rgb(255 255 255);
  --color-surface-container-low: rgb(243 243 250);
  --color-surface-container: rgb(237 237 244);
  --color-surface-container-high: rgb(62, 56, 65);
  --color-surface-container-highest: rgb(50, 44, 55);
  // Fonts
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-bolder: 900;
  --font-size-2xs: 12px;
  --font-size-xs: 14px;
  --font-size-s: 18px;
  --font-size-m: 21px;
  --font-size-l: 24px;
  --font-size-xl: 30px;
  --font-size-2xl: 33px;
  --font-size-3xl: 36px;
  --font-size-4xl: 42px;
  --font-size-5xl: 48px;
  --line-height-xs: 16px;
  --line-height-s: 18px;
  --line-height-m: 24px;
  --line-height-l: 30px;
  --line-height-xl: 33px;
  --line-height-2xl: 36px;
  --line-height-3xl: 42px;
  --line-height-4xl: 48px;
  --line-height-5xl: 56px;
  // Colors
  --background-color: var(--color-background);
  --text-color: var(--color-on-background);
  // Accessibility
  --a18n-color-bg: #4e4e4e;
  --a18n-color-icon: rgb(255, 255, 255);
  --a18n-color-text: rgb(255, 255, 255);
  --a18n-font-size: 18px;
  --a18n-font-weight: 600;
  --a18n-line-height: 18px;
}
