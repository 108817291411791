footer#footer {
  .footer {
    max-width: 100% !important;
    background-color: var(--color-surface-container-high);
    color: var(--color-inverse-on-surface) !important;
    .footer_links {
      --social-network-icon-color-main: var(
        --color-inverse-on-surface
      ) !important;
      --social-network-icon-color-hover: var(--color-primary) !important;
    }
    ul {
      li {
        border-right: 1px solid var(--color-inverse-on-surface);
        a {
          color: var(--color-inverse-on-surface) !important;
          &:hover {
            color: var(--color-primary) !important;
          }
        }
      }
    }
  }
  .footer-branding {
    padding: $spacing-small;
    .plonegovbr {
      color: var(--color-main) !important;
      &:hover {
        color: var(--color-primary) !important;
      }
    }
  }
}
