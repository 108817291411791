// Header wrapper
.header-wrapper {
  margin-top: $spacing-small;
  background-color: var(--color-primary-container);
  .header.layout {
    .search-wrapper {
      .search {
        .search-bar {
          top: -180px;
          left: 0;
          background-color: var(--color-surface-container-high);
          &.active {
            top: -45px;
            height: 209px;
          }
        }
      }
    }
  }
  .header.layout {
    max-width: 100%;
    .logo {
      display: flex;
      justify-content: center;
      a {
        line-height: 0px;
        img {
          height: 140px;
        }
      }
    }
    .logo-nav-wrapper {
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
      background: var(--color-primary-container);
    }
  }
  // Mobile Navigation
  .mobile-nav {
    div.menu-drawer {
      .search-header {
        display: none;
      }
    }
    .hamburger:before,
    .hamburger::after,
    .hamburger-inner:after,
    .hamburger-inner::before {
      background-color: var(--color-on-primary);
    }
  }
}

body.is-unauthorized {
  .header-wrapper {
    .header.layout {
      .search-wrapper {
        display: none !important;
      }
    }
  }
}
